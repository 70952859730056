import React, { useState } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'

import SEO from '../../components/seo'
import Section from '../../components/Common/Section'
import { DescriptionBlock2 } from '../../components/description-block'
import BannerSection from '../../components/BannerSection'
import { PreviewBlock2 } from '../../components/preview-block'
import List from '../../components/Common/List'
import { Button } from '../../components/Common/Button'
import ContactForm, { IContactModalState } from '../../components/contact-form'
import {
  ModuleSection2,
  ModuleSectionItem2,
} from '../../components/module-section-2'
import DashboardModuleBlock from '../../components/DashboardModuleBlock'
import RequestDemo from '../../components/RequestDemo'
import ModalDownloadFile from '../../components/Common/ModalDownloadFile'
import Typography from '../../components/Common/Typography'
import PopUp from '../../components/Common/PopUp'

import { GlobalContext } from '../../context/GlobalContext'
import { useTranslations } from '../../hooks/use-translations'
import { useClientSide } from '../../hooks/useClientSide'
import { createFillArray, isBrowser, toLink } from '../../utils'
import { AppRegionEnum } from '../../types/app-region.enum'
import {
  downloadPriceListLink,
  signalDocsDownloadPresentationLink,
  signaxDocsDownloadPresentationLink,
} from '../../data'
import SgnlImageSeo from '../../assets/images/seo/sgnl/docs.png'

import * as s from './Docs.module.scss'
import { LocalesEnum } from '../../types/locales.enum'

const DocsPage: React.FC = () => {
  const { t, getPath, locale } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const { region, location } = React.useContext(GlobalContext)
  const locationState = location?.state
  const fillArrayLength = region === AppRegionEnum.Ru ? 8 : 7

  const [modal, setModal] = useState<IContactModalState>()
  const [presentationModal, setPresentationModal] = React.useState(false)
  const [popUp, setPopUp] = React.useState(false)
  const clientSide = useClientSide()

  React.useEffect(() => {
    region === AppRegionEnum.Ru && clientSide && setPopUp(true)
  }, [clientSide, region])

  React.useEffect(() => {
    if (isBrowser()) {
      if (locationState?.presentationModalOpened) {
        setPresentationModal(true)
        locationState.presentationModalOpened = false
      }
    }
  }, [locationState, t])

  return (
    <>
      <SEO
        title={t('seo_title_docs')}
        description={t('Docs description')}
        image={region === AppRegionEnum.Ru ? SgnlImageSeo : undefined}
        keywords={region === AppRegionEnum.Us ? t('keywords_docs') : undefined}
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        imageMobile={images.bannerMobile.childImageSharp.gatsbyImageData}
        title={`${t('Signal')} Docs`}
        colorShadow="dark"
      />
      <Section>
        <DescriptionBlock2
          title={t('Docs title')}
          highlightTitleWordsByIdx={
            locale === LocalesEnum.Ru
              ? [9, 10, 11]
              : locale === LocalesEnum.Us
              ? [4, 5]
              : [5, 6, 7]
          }
        />
        <PreviewBlock2
          src={images[`preview_${region}`].childImageSharp.gatsbyImageData}
          mobileImageSrc={
            images[`preview_mobile_${region}`].childImageSharp.gatsbyImageData
          }
          srcTitle={t('Docs title')}
          srcPosition="right"
        >
          <div className={s.docs_module}>
            <List>
              {createFillArray(fillArrayLength, idx =>
                t(`_docs_text${idx + 1}`)
              ).map((text, idx) => (
                <li key={idx}>{text}</li>
              ))}
            </List>
            <div className={s.btns}>
              <Button
                isFullWidth
                color="white"
                onClick={() =>
                  region === AppRegionEnum.Ru
                    ? setPresentationModal(true)
                    : toLink(signaxDocsDownloadPresentationLink)
                }
              >
                {t('Download presentation')}
              </Button>
              <Button
                isFullWidth
                onClick={() =>
                  setModal({
                    title:
                      region === AppRegionEnum.Ru
                        ? t('Download trial version')
                        : t('Request a demo of Signal'),
                    requestType: region === AppRegionEnum.Ru ? 'Trial' : 'Demo',
                  })
                }
              >
                {region === AppRegionEnum.Ru
                  ? t('Download trial version')
                  : t('Request a demo_1')}
              </Button>
            </div>
          </div>
        </PreviewBlock2>
      </Section>
      <ModuleSection2
        title={
          region === AppRegionEnum.Ru
            ? t('Module features')
            : t('Module advantages')
        }
      >
        <ModuleSectionItem2
          title={t('_docs_subtitle')}
          description={t('_docs_lead')}
          quote={{
            author: region === AppRegionEnum.Ru ? 'author_21' : 'Andrew S.',
            position: t('CIO'),
            text: t('_docs_quote'),
          }}
          image={images.image.childImageSharp.gatsbyImageData}
        />
        <ModuleSectionItem2
          title={t('_docs_subtitle2')}
          positionTitle="right"
          description={t('_docs_lead2')}
          quote={{
            author: region === AppRegionEnum.Ru ? 'author_22' : 'author_11',
            position:
              region === AppRegionEnum.Ru ? t('Foreman') : t('QA/QC Manager'),
            text: t('_docs_quote2'),
          }}
          image={images[`image2_${region}`].childImageSharp.gatsbyImageData}
          reverseElements
        />
        <ModuleSectionItem2
          title={t('_docs_subtitle3')}
          description={t('_docs_lead3')}
          quote={{
            author: region === AppRegionEnum.Ru ? 'author_20' : 'author_10',
            position: t('BIM-manager'),
            text: t('_docs_quote3'),
          }}
          image={images[`image3_${region}`].childImageSharp.gatsbyImageData}
        />
        <ModuleSectionItem2
          title={t('_docs_subtitle4')}
          positionTitle="right"
          description={t('_docs_lead4')}
          quote={{
            author:
              region === AppRegionEnum.Ru ? 'author_21' : 'Sergey Kazantsev',
            position:
              region === AppRegionEnum.Ru ? t('CIO') : t('Project manager'),
            text: t('_docs_quote4'),
          }}
          reverseElements
          image={images.image4.childImageSharp.gatsbyImageData}
        />
        <ModuleSectionItem2
          title={t('_docs_subtitle5')}
          description={t('_docs_lead5')}
          quote={{
            author:
              region === AppRegionEnum.Ru ? 'Sergey Kazantsev' : 'author_10',
            position:
              region === AppRegionEnum.Ru
                ? t('Project manager')
                : t('BIM-manager'),
            text: t('_docs_quote5'),
          }}
          image={images[`image5_${region}`].childImageSharp.gatsbyImageData}
        />
        <DashboardModuleBlock reverseElements />
      </ModuleSection2>
      {region === AppRegionEnum.Ru ? (
        <RequestDemo
          title={t('Trial period')}
          imageData={{
            image: images.demo_ru.childImageSharp.gatsbyImageData,
            title: `Docs ${t('Demo')}`,
          }}
          appData={{
            text: t('Docs connect'),
            btnText: t('Request a demo_1'),
            onClick: () =>
              setModal({
                title: t('Request a demo of Signal'),
                requestType: 'Demo',
              }),
            btnColor: 'white',
          }}
          moduleData={{
            text: t('Docs demo'),
            btnText: t('Download price list'),
            btnColor: 'transparent',
            onClick: () => toLink(downloadPriceListLink),
          }}
          buttonsElements={
            <Button
              size="large"
              color="transparent"
              onClick={() =>
                setModal({
                  title: t('Request testing'),
                  requestType: 'Trial',
                })
              }
            >
              {t('Request testing')}
            </Button>
          }
        />
      ) : (
        <RequestDemo
          title={t('Request a demo_1')}
          imageData={{
            image: images.demo_us.childImageSharp.gatsbyImageData,
            title: `Docs ${t('Demo')}`,
          }}
          moduleData={{
            text: t('Docs demo'),
            btnText: t('Request a demo_1'),
            onClick: () =>
              setModal({
                title: t('Request a demo of Signal'),
                requestType: 'Demo',
              }),
            btnColor: 'white',
          }}
        />
      )}
      {modal && (
        <ContactForm
          onClose={() => setModal(undefined)}
          title={modal.title}
          requestType={modal.requestType}
        />
      )}
      {region === AppRegionEnum.Ru && presentationModal && (
        <ModalDownloadFile
          fileUrl={signalDocsDownloadPresentationLink}
          title={t('Download presentation')}
          image={images.presentatio_preview.childImageSharp.gatsbyImageData}
          imageTitle="SIGNAL DOCS"
          onClose={() => setPresentationModal(false)}
        />
      )}
      {popUp && (
        <PopUp
          onClose={() => setPopUp(false)}
          title={t('DOCS migration pop-up title')}
        >
          <Typography className={s.text_popUp} variant="body1" mb={16}>
            {t('DOCS migration pop-up text')}
          </Typography>
          <Button
            isFullWidth
            onClick={() => {
              setPopUp(false)
              navigate(getPath('/bim-360-migrate/'))
            }}
          >
            {t('DOCS migration pop-up button text')}
          </Button>
        </PopUp>
      )}
    </>
  )
}

export default DocsPage

const imagesQuery = graphql`
  query {
    banner: file(relativePath: { eq: "docs/banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    bannerMobile: file(relativePath: { eq: "docs/banner-mobile.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    preview_us: file(relativePath: { eq: "docs/signax-preview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 624, placeholder: BLURRED)
      }
    }
    preview_ru: file(relativePath: { eq: "docs/signal-preview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 624, placeholder: BLURRED)
      }
    }
    preview_mobile_us: file(
      relativePath: { eq: "docs/signax-preview-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    preview_mobile_ru: file(
      relativePath: { eq: "docs/signal-preview-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image: file(relativePath: { eq: "docs/image.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image2_us: file(relativePath: { eq: "docs/signax-image-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image2_ru: file(relativePath: { eq: "docs/signal-image-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image3_us: file(relativePath: { eq: "docs/signax-image-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image3_ru: file(relativePath: { eq: "docs/signal-image-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image4: file(relativePath: { eq: "docs/image-4.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image5_us: file(relativePath: { eq: "docs/signax-image-5.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image5_ru: file(relativePath: { eq: "docs/signal-image-5.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    demo_us: file(relativePath: { eq: "docs/signax-demo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 764, placeholder: BLURRED)
      }
    }
    demo_ru: file(relativePath: { eq: "docs/signal-demo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 630, placeholder: BLURRED)
      }
    }
    presentatio_preview: file(
      relativePath: { eq: "signal-docs-preview-presentation.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 552, placeholder: BLURRED)
      }
    }
  }
`
